@import "vars.scss";

.swal2-confirm {
  background-color: $color-eMenu !important;
}


.swal2-height-auto {
  height: 100% !important;
}

.tableGrid {
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr)) !important;
  align-content: start;

  .table {
    ::ng-deep .mat-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: normal;
      flex: 1;
    }

    height: 90px !important;
    font-size: 1.05em !important;

    position: relative;
    border: 0;
    color: white;
    display: flex;
    padding: 4px;

    .name {
      flex: 1;
      align-items: center;
      display: flex;
    }
  }
}

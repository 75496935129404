/* You can add global styles to this file, and also import other style files */
@import "angularMaterial.scss";

@import "~ngx-toastr/toastr.css";

@import "swal.scss";

@import "scrollbar.scss";

@import "isBusy.scss";

div.error {
  border: 1px solid red;
  background-color: rgba($color: red, $alpha: 0.1);
  padding: 0.5rem 1rem;
  text-align: center;
}

span.error {
  color: red;
}

html,
body {
  height: 100%;
  --primary: #890e4f;
}

button.flat {
  background-color: transparent;
  border: none;
}

.price {
  font-size: 1em;
  font-weight: 500;
  color: $color-eMenu;
  span {
    margin-right: 5px;
    font-size: 0.8em;
    color: grey;
  }
}

.newVersionAvailableSnackBar {
  button {
    color: white;
  }
}

ul {
  margin: 0;
  padding: 0;
}

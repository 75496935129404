// eMenu boje
$color-eMenu: #890e4f;
$color-eMenu-lighten-1: #952761;



$md-emenupalette: (
  50: #f1e2ea,
  100: #dcb7ca,
  200: #c487a7,
  300: #ac5684,
  400: #9b3269,
  500: #890e4f,
  600: #810c48,
  700: #760a3f,
  800: #6c0836,
  900: #590426,
  A100: #ff8bb1,
  A200: #ff588f,
  A400: #ff256c,
  A700: #ff0b5b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-emenupaletteaccent: (
  50 : #e0e4f1,
  100 : #b3bcdb,
  200 : #808fc3,
  300 : #4d62ab,
  400 : #264099,
  500 : #001e87,
  600 : #001a7f,
  700 : #001674,
  800 : #00126a,
  900 : #000a57,
  A100 : #898eff,
  A200 : #565dff,
  A400 : #232cff,
  A700 : #0914ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

html {
  height: 100%;
}

@font-face {
  font-family: "ubuntu";
  src: url("/assets/fonts/UbuntuMono-R.ttf");
}
body {
  font-family: "Arial";
  padding: 0;
  margin: 0;
  height: 100%;
}

.waiterApp {
  @import "tablegrid.scss";

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-gap: 2px;
    padding: initial;

    .item {
      flex: 0 0;
      height: 60px;
      margin: 0 1px 1px 0;
      overflow: hidden;
      font-size: 12px;

      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .price {
    span {
      display: none;
    }
  }
}

div.warning {
  border: 1px solid #ffeb3b;
  background-color: #ffffcc;
  text-align: center;
  padding: 1rem;
}

button.flat {
  border: none;
}

button.item {
  white-space: normal;
  line-height: 1rem;
  border: 1px solid black !important;
}

.noTouch {
  touch-action: pan-y;
  user-select: pan-y;
  -webkit-user-drag: pan-y;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@import 'vars.scss';

/* !important is needed sometimes */
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
 }

 /* Track */
::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.3) !important;
   -webkit-border-radius: 10px !important;
   border-radius: 10px !important;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   -webkit-border-radius: 10px !important;
   border-radius: 10px !important;
   background: $color-eMenu !important;
   -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.5) !important;

 }
 ::-webkit-scrollbar-thumb:window-inactive {
   background: $color-eMenu !important;
 }

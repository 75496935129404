// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

@import "vars.scss";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$emenu-primary: mat-palette($md-emenupalette);
$emenu-accent: mat-palette($md-emenupaletteaccent);

// Create the theme object. A theme consists of configurations for in
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
$mat-theming-theme: mat-light-theme($emenu-primary, $emenu-accent);

/* theme */
@include angular-material-theme($mat-theming-theme);

.cdk-overlay-pane {
  max-width: 90vw !important;
}

@import "matDialog.scss";

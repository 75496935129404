@import "vars.scss";

.mat-dialog-title {
  display: flex !important;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  .title {
    font-weight: 700;
  }

  .close-dialog {
    color: $color-eMenu;
    font-size: 2rem;
    position: absolute;
    right: -10px;
    top: -15px;
  }
}

@font-face {
  font-family: "eMenu";
  src: url("../assets/fonts/eMenu.eot?zoiwr");
  src: url("../assets/fonts/eMenu.eot?zoiwr#iefix") format("embedded-opentype"),
    url("../assets/fonts/eMenu.ttf?zoiwr") format("truetype"),
    url("../assets/fonts/eMenu.woff?zoiwr") format("woff"),
    url("../assets/fonts/eMenu.svg?zoiwr#eMenu") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "eMenu" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  display: unset;
  font-size: inherit;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-no-synchronize:before {
  content: "\e954";
}
.icon-logo1:before {
  content: "\e953";
}
.icon-virtualProduct:before {
  content: "\e93a";
}
.icon-waiter:before {
  content: "\e94e";
}
.icon-order:before {
  content: "\e92b";
}
.icon-info:before {
  content: "\e946";
}
.icon-logo:before {
  content: "\e92a";
}
.icon-addToGroup:before {
  content: "\e938";
}
.icon-back:before {
  content: "\e94d";
}
.icon-pendingOrder:before {
  content: "\e94c";
}
.icon-bell:before {
  content: "\e94b";
}
.icon-logout:before {
  content: "\e943";
}
.icon-history:before {
  content: "\e93e";
}
.icon-favorite:before {
  content: "\e93c";
}
.icon-notfavorite:before {
  content: "\e93d";
}
.icon-search:before {
  content: "\e93b";
}
.icon-home:before {
  content: "\e937";
}
.icon-house:before {
  content: "\e937";
}
.icon-modifiers:before {
  content: "\e936";
}
.icon-product:before {
  content: "\e930";
}
.icon-moveToGroup:before {
  content: "\e931";
}
.icon-delete:before {
  content: "\e932";
}
.icon-checkbox-unchecked:before {
  content: "\e933";
}
.icon-checkbox:before {
  content: "\e933";
}
.icon-unchecked:before {
  content: "\e933";
}
.icon-square:before {
  content: "\e933";
}
.icon-checkbox-checked:before {
  content: "\e934";
}
.icon-checkbox1:before {
  content: "\e934";
}
.icon-tick:before {
  content: "\e934";
}
.icon-checked:before {
  content: "\e934";
}
.icon-selected:before {
  content: "\e934";
}
.icon-add:before {
  content: "\e935";
}
.icon-childProduct:before {
  content: "\e92e";
}
.icon-settings:before {
  content: "\e90f";
}
.icon-employee:before {
  content: "\e910";
}
.icon-drink:before {
  content: "\e941";
}
.icon-food:before {
  content: "\e942";
}
.icon-barbecue:before {
  content: "\e93f";
}
.icon-sandwich:before {
  content: "\e940";
}
.icon-alcohol:before {
  content: "\e911";
}
.icon-nonAlcohol:before {
  content: "\e912";
}
.icon-cigarette:before {
  content: "\e913";
}
.icon-kidsMenu:before {
  content: "\e914";
}
.icon-desert:before {
  content: "\e915";
}
.icon-default:before {
  content: "\e916";
}
.icon-dnevni-meni:before {
  content: "\e917";
}
.icon-brekfast:before {
  content: "\e918";
}
.icon-hotel-nocenje:before {
  content: "\e919";
}
.icon-inspiracija-masta:before {
  content: "\e91a";
}
.icon-iz-baste:before {
  content: "\e91b";
}
.icon-jelovnik:before {
  content: "\e91c";
}
.icon-karta-pica:before {
  content: "\e91d";
}
.icon-coctail:before {
  content: "\e91e";
}
.icon-startup:before {
  content: "\e91f";
}
.icon-nacionalna-jela:before {
  content: "\e920";
}
.icon-sallate:before {
  content: "\e921";
}
.icon-pasta:before {
  content: "\e922";
}
.icon-beer:before {
  content: "\e923";
}
.icon-prilozi:before {
  content: "\e924";
}
.icon-riba:before {
  content: "\e925";
}
.icon-cheese:before {
  content: "\e926";
}
.icon-soup:before {
  content: "\e927";
}
.icon-hotDrinks:before {
  content: "\e928";
}
.icon-vine:before {
  content: "\e929";
}
.icon-wheat:before {
  content: "\e900";
}
.icon-sulphurdioxide:before {
  content: "\e901";
}
.icon-treenut:before {
  content: "\e902";
}
.icon-sesame:before {
  content: "\e903";
}
.icon-soya:before {
  content: "\e904";
}
.icon-mustard:before {
  content: "\e905";
}
.icon-peanut:before {
  content: "\e906";
}
.icon-molluscs:before {
  content: "\e907";
}
.icon-milk:before {
  content: "\e908";
}
.icon-crustacens:before {
  content: "\e909";
}
.icon-lupin:before {
  content: "\e90a";
}
.icon-fish:before {
  content: "\e90b";
}
.icon-eggs:before {
  content: "\e90c";
}
.icon-celery:before {
  content: "\e90d";
}
.icon-flow-children:before {
  content: "\e92f";
}
.icon-flash_off:before {
  content: "\e951";
}
.icon-flash_on:before {
  content: "\e952";
}
.icon-flip_camera:before {
  content: "\e950";
}
.icon-import_export:before {
  content: "\e94f";
}
.icon-refresh:before {
  content: "\e94a";
}
.icon-eye:before {
  content: "\e949";
}
.icon-content_copy:before {
  content: "\e948";
}
.icon-local_printshop:before {
  content: "\e947";
}
.icon-print:before {
  content: "\e947";
}
.icon-close:before {
  content: "\e945";
}
.icon-send:before {
  content: "\e944";
}
.icon-drag_indicator:before {
  content: "\e939";
}
.icon-qr_code:before {
  content: "\e90e";
}
.icon-suggestion:before {
  content: "\e92c";
}
.icon-group:before {
  content: "\e92d";
}
